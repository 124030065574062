"use client";

import { useState, useEffect, useRef } from "react";
import { Menu } from "lucide-react";
import { XCircle, Instagram } from "lucide-react";
import { Twitter } from "lucide-react";
import { Mail } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const menuItems = [
  { text: "Features", url: "#" },
  { text: "Resources", url: "#" },
  { text: "Company", url: "#" },
  { text: "Contact Sales", url: "#" },
];

const iconComponents = [
  { id: 1, Icon: Instagram, url: "#" },
  { id: 2, Icon: Twitter, url: "#" },
  { id: 3, Icon: Mail, url: "#" },
];

const IconList = () => (
  <div className="absolute bottom-5 left-0 right-0">
    <div className="flex items-center justify-evenly space-x-2 px-6 py-4">
      {iconComponents.map(({ id, Icon, url }) => (
        <a key={id} href={url}>
          <Icon className="text-2xl text-neutral-900 dark:text-white" />
        </a>
      ))}
    </div>
  </div>
);

interface MobileMenuProps {
  onClose: () => void;
}

function MobileMenu({ onClose }: MobileMenuProps) {
  const mobileMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [onClose]);

  return (
    <nav
      className="absolute bottom-0 left-0 right-0 top-0 z-50 max-w-[270px] border-r border-dashed border-neutral-400/30 bg-neutral-100/60 shadow-sm backdrop-blur-md transition-all duration-300 dark:bg-neutral-800/60 md:max-w-[300px] lg:flex lg:max-w-[300px] lg:flex-col lg:shadow-none"
      ref={mobileMenuRef}
    >
      <a href="/">
        <div className="flex items-center space-x-2 p-5">
          <div className="flex items-center space-x-2">
            <div className="rounded-full bg-neutral-500/20 p-2">
              <img
                className="h-5 w-5"
                src="/android-chrome-512x512.png"
                alt="MagicUI Logo"
              />
            </div>
            <h2 className="font-semibold text-neutral-900 dark:text-white">
              MagicUI
            </h2>
          </div>
        </div>
      </a>
      <div className="lg:hidden">
        <ul className="flex-col space-y-5 px-8 py-5 pb-8 text-[1.1rem] font-semibold text-neutral-900 dark:text-white">
          {menuItems.map((menuItem, index) => (
            <li key={index}>
              <a href={menuItem.url}>
                <button>{menuItem.text}</button>
              </a>
            </li>
          ))}
        </ul>
        <div className="space-y-2">
          <div className="px-6 lg:flex lg:items-center">
            <a
              href="#"
              className="flex w-full items-center justify-center  rounded-full bg-neutral-900 px-5 py-2 text-white transition duration-200 hover:shadow-sm hover:drop-shadow-sm dark:bg-neutral-200 dark:text-neutral-900"
            >
              <span>Join now</span>
            </a>
          </div>
        </div>
      </div>
      <IconList />
    </nav>
  );
}

export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = (event: any) => {
    event.stopPropagation();
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleEscapeKey = (event: any) => {
      if (event.key === "Escape") {
        setIsMobileMenuOpen(false);
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMobileMenuOpen]);

  return (
    <header className="w-full bg-neutral-100 px-2 py-2 dark:bg-neutral-900 lg:relative lg:py-5">
      <nav className="flex items-center justify-between rounded-full px-5 py-2.5 lg:border-none lg:px-2 lg:py-2">
        <a href="/">
          <div className="flex items-center space-x-2">
            <h2 className="text-2xl font-semibold text-neutral-900 dark:text-white">
              MagicUI
            </h2>
          </div>
        </a>
        <div className="hidden items-center gap-x-10 lg:flex">
          <ul className="flex space-x-10 text-base font-semibold text-neutral-900 dark:text-white">
            {menuItems.map((menuItem, index) => (
              <li key={index}>
                <a href={menuItem.url}>
                  <button>{menuItem.text}</button>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="hidden gap-x-5 lg:flex lg:items-center">
          <button className="flex items-center justify-center rounded-md bg-neutral-900 px-5 py-2 font-semibold text-white shadow-[2px_2px_0px_3px_rgba(0,0,0,0.8)] ring-neutral-500 transition-all duration-200 hover:shadow-none hover:ring-2 hover:drop-shadow dark:bg-white dark:text-neutral-900 dark:shadow-[1px_1px_0px_2px_rgba(255,255,255,0.5)] dark:ring-neutral-200/70 dark:hover:shadow-none dark:hover:ring-2">
            <span>Try for free</span>
          </button>
          <button className="flex items-center justify-center rounded-md bg-white px-5 py-2 font-semibold text-neutral-900 shadow-[2px_2px_0px_3px_rgba(0,0,0,0.9)] ring-neutral-500 transition-all duration-200 hover:shadow-none hover:ring-2 hover:drop-shadow dark:bg-neutral-900 dark:text-white dark:shadow-[1px_1px_0px_2px_rgba(255,255,255,0.9)] dark:ring-neutral-200/70 dark:hover:shadow-none dark:hover:ring-2">
            <span>Login</span>
          </button>
        </div>
        <div className="flex items-center justify-center lg:hidden">
          <button
            className={`focus:outline-none ${
              isMobileMenuOpen
                ? "rounded-full bg-neutral-200 text-neutral-800"
                : "text-neutral-200"
            }`}
            onClick={handleMobileMenuToggle}
          >
            {isMobileMenuOpen ? (
              <XCircle className="text-2xl focus:outline-none active:scale-110 active:text-neutral-200" />
            ) : (
              <Menu className="text-2xl text-neutral-900 focus:outline-none active:scale-110 dark:text-white" />
            )}
          </button>
        </div>
      </nav>
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{
              duration: 0.35,
              damping: 30,
              stiffness: 300,
              ease: "easeOut",
            }}
            className="fixed inset-0 z-50 overflow-auto bg-transparent lg:hidden"
          >
            <MobileMenu onClose={handleMobileMenuClose} />
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}
